import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import axios from "axios"
import VueAxios from "vue-axios"
import { BootstrapVue } from 'bootstrap-vue'
import VueCarousel from 'vue-carousel';
import i18n from './i18n'

const axiosCustom = axios.create();

Vue.use(BootstrapVue)
Vue.use(VueCarousel)
Vue.use(VueAxios, axiosCustom);

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
