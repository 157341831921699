let shop_url = '';
let privacy_link = '';
let currency = '';

function getPrivacy(locale) {
    if (locale === 'cs') {
        privacy_link = 'ochrana-osobnich-udaju';
    } else if (locale === 'hu') {
        privacy_link = 'adatvedelem';
    } else if (locale === 'sk') {
        privacy_link = 'ochrana-osobnych-udajov';
    } else {
        privacy_link = 'polityka-prywatnosci';
    }

    return privacy_link;
}

function getLink(locale) {
    if (locale === 'cs') {
        shop_url = '.cz/';
    } else if (locale === 'hu') {
        shop_url = '.hu/';
    } else if (locale === 'sk') {
        shop_url = '.sk/';
    } else {
        shop_url = '.com/';
    }

    return shop_url;
}

function getCurrency(locale) {
    if (locale === 'cs') {
        currency = 'Kč';
    } else if (locale === 'hu') {
        currency = 'Ft';
    } else if (locale === 'sk') {
        currency = '€';
    } else if (locale === 'de') {
        currency = '€';
    } else {
        currency = 'zł';
    }

    return currency;
}

export { getLink, getPrivacy, getCurrency }