<template>
  <main>
    <section class="main-section" id="main">
      <video autoplay muted loop class="video-bg">
        <source src="@/assets/video/video.mp4" type="video/mp4">
      </video>

      <button @click="scrollMeTo('about')" class="down-btn">
        <img src="@/assets/img/icons/arrow.svg" alt="logo"/>
      </button>

      <div class="main-info">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <h2 class="title" v-html="$t('baner.eco_design_baner')"></h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-section" id="about">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <img src="@/assets/img/icons/logo-jannowak.svg" alt="logo" class="jannowak-logo"/>
            <img src="@/assets/img/icons/love.svg" alt="logo" class="love-img"/>
            <img src="@/assets/img/icons/design.svg" alt="logo" class="design-img"/>
          </div>
          <div class="col-lg-6 col-md-12">
            <p class="desc">{{ $t('collection.eco_design_collection1') }}</p>
            <p class="desc">{{ $t('collection.eco_design_collection2') }}</p>
            <a :href="url + 'eco-design'" class="main-button buy-btn">{{ $t('header.eco_design_buy_now') }}</a>
          </div>
        </div>
      </div>
    </section>

    <section class="inspiration-section" id="inspiration">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-top">
              <h2 class="title">{{ $t('getinspired.eco_design_getinspired_title') }}</h2>
            </div>

            <b-tabs lazy v-model="tab_index">
              <b-link target=""></b-link>

              <b-tab :title="$t('getinspired.eco_design_getinspired_all')" ref="all" active>

                <div class="thumbnails-content">
                  <div class="thumbnails-block-space" >
                    <div class="thumbnails-block" v-for="(render, index) in renders" :key="index" @click="showGallery(index, render.name, render.gallery_img, render.products)">
                      <img :src="render.thumbnail_img" :alt="render.name" class="thumbnail-img"/>
                      <h3 v-if="index !== 8" class="thumbnail-block-title"><span>{{ render.category }}</span>{{ render.name }}</h3>

                      <div v-if="index === 8" class="special-thumbnail-info">
                        <img src="@/assets/img/icons/logo-design-white.svg" alt="logo" class="design-img"/>
                        <a :href="url + 'eco-design'"  class="main-button buy-btn">{{ $t('header.eco_design_buy_now') }}</a>
                      </div>
                    </div>
                    <br>
                  </div>
                </div>

              </b-tab>

              <b-tab :title="$t('getinspired.eco_design_getinspired_home_office')" ref="office">

                <div class="thumbnails-content">
                  <div class="thumbnails-block" v-for="(render, index) in renders" :key="index" v-if="render.category === $t('getinspired.eco_design_getinspired_home_office')" @click="showGallery('', render.name, render.gallery_img, render.products)">
                    <img :src="render.thumbnail_img" :alt="render.name" class="thumbnail-img"/>
                    <h3 class="thumbnail-block-title"><span>{{ render.category }}</span>{{ render.name }}</h3>
                  </div>
                </div>

              </b-tab>

              <b-tab :title="$t('getinspired.eco_design_getinspired_cloakroom')" ref="szatnia">

                <div class="thumbnails-content">
                  <div class="thumbnails-block-space" >
                    <div class="thumbnails-block" v-for="(render, index) in renders" :key="index" v-if="render.category === $t('getinspired.eco_design_getinspired_cloakroom')" @click="showGallery('', render.name, render.gallery_img, render.products)">
                      <img :src="render.thumbnail_img" :alt="render.name" class="thumbnail-img"/>
                      <h3 class="thumbnail-block-title"><span>{{ render.category }}</span>{{ render.name }}</h3>
                    </div>
                    <br>
                  </div>
                </div>

              </b-tab>
            </b-tabs>

            <div class="bottom-tabs-links">
              <button class="bottom-tabs-link" @click="openAllTab">{{ $t('getinspired.eco_design_getinspired_all') }}</button>
              <button class="bottom-tabs-link" @click="openOfficeTab">{{ $t('getinspired.eco_design_getinspired_home_office') }}</button>
              <button class="bottom-tabs-link" @click="openSzatniaTab">{{ $t('getinspired.eco_design_getinspired_cloakroom') }}</button>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section class="wardrobes-section" id="wardrobes">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-top">
              <h2 class="title">{{ $t('all_wardrobes.eco_design_wardrobes_title') }}</h2>
              <p class="desc">{{ $t('all_wardrobes.eco_design_wardrobes_dsc') }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="wardrobes-slider-block">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">

              <carousel :perPageCustom="[[1199, 4],[840, 3], [620, 2], [420, 1]]" :navigation-enabled="true" :navigation-next-label="nextLabel" :navigation-prev-label="prevLabel">
                <slide v-for="(product, index) in products" :key="index">
                  <div class="wardrobe-item">
                    <a :href="url + product.url" target="_blank">
                      <img :src="imgix_url + product.photos[2][0].original" alt="wardrobe" class="wardrobe-img"/>
                    </a>

                    <p class="price" v-if="$i18n.locale === 'cs'">{{ roundPrice(product.prices.brutto[5].CZK.price) }} {{ currency }}</p>
                    <p class="price" v-else-if="$i18n.locale === 'sk'">{{ roundPrice(product.prices.brutto[6].EUR.price) }} {{ currency }}</p>
                    <p class="price" v-else-if="$i18n.locale === 'hu'">{{ roundPrice(product.prices.brutto[7].HUF.price) }} {{ currency }}</p>
                    <p class="price" v-else>{{ roundPrice(product.prices.brutto[1].PLN.price) }} {{ currency }}</p>

                    <p class="title">
                      <a :href="url + product.url" target="_blank">{{ product.name }}</a>
                    </p>
                    <a :href="url + product.url" class="product-buy-btn" target="_blank">{{ $t('header.eco_design_buy_now') }}</a>
                  </div>
                </slide>
              </carousel>

            </div>
          </div>
        </div>
      </div>

    </section>

    <section class="technology-section" id="technology">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-top">
              <h2 class="title">{{ $t('ecowoodtech.eco_design_tech_title') }}</h2>
              <p class="desc">{{ $t('ecowoodtech.eco_design_tech_dsc') }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="technology-center-container">
        <img src="@/assets/img/icons/tech-logo-v2.svg" alt="item" class="technology-tech-logo-img"/>

        <img src="@/assets/img/technology/eco_wood.png" alt="item" class="technology-img wood" :class="{ hover: hover, active: active === 'wood' }"/>
        <img src="@/assets/img/technology/ceramid.png" alt="item" class="technology-img ceramid" :class="{ hover: hover, active: active === 'ceramid' }"/>
        <img src="@/assets/img/technology/hq_steel.png" alt="item" class="technology-img hq_steel" :class="{ hover: hover, active: active === 'hq_steel' }"/>
        <img src="@/assets/img/technology/protective.png" alt="item" class="technology-img protective" :class="{ hover: hover, active: active === 'protective' }"/>

        <div class="tech-info-block first" @mouseover="hover = true; active = 'wood'" @mouseleave="hover = false; active = ''">
          <h3>
            <img src="@/assets/img/icons/art-logo-v2.svg" alt="item" class="art-logo-img"/>
          </h3>
          <p class="desc">{{ $t('ecowoodtech.eco_design_ecowood_art') }}</p>
        </div>
        <div class="tech-info-block second" @mouseover="hover = true; active = 'hq_steel'" @mouseleave="hover = false; active = ''">
          <h3><span class="subtitle">HQ Steel</span> base</h3>
          <p class="desc">{{ $t('ecowoodtech.eco_design_steel_base') }}</p>
        </div>
        <div class="tech-info-block third" @mouseover="hover = true; active = 'ceramid'" @mouseleave="hover = false; active = ''">
          <h3><span class="subtitle">Ceramid </span> membrane</h3>
          <p class="desc">{{ $t('ecowoodtech.eco_design_ceramid_membrane') }}</p>
        </div>
        <div class="tech-info-block fourth" @mouseover="hover = true; active = 'protective'" @mouseleave="hover = false; active = ''">
          <h3><span class="subtitle">Protective </span> shield</h3>
          <p class="desc">{{ $t('ecowoodtech.eco_design_procetive_shield') }}</p>
        </div>

        <a :href="url + 'eco-design'"  class="main-button buy-btn">{{ $t('header.eco_design_buy_now') }}</a>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="technology-item">
              <img src="@/assets/img/technology/technology1.svg" alt="item" class="technology-item-img"/>
              <div class="technology-item-content">
                <h3>{{ $t('advantages.eco_design_quality_title') }}</h3>
                <p>{{ $t('advantages.eco_design_tech_quality_dsc') }}</p>
              </div>
            </div>

            <div class="technology-item">
              <img src="@/assets/img/technology/technology2.svg" alt="item" class="technology-item-img"/>
              <div class="technology-item-content">
                <h3>{{ $t('advantages.eco_design_paints_title') }}</h3>
                <p>{{ $t('advantages.eco_design_paints_dsc') }}</p>
              </div>
            </div>

            <div class="technology-item">
              <img src="@/assets/img/technology/technology3.svg" alt="item" class="technology-item-img"/>
              <div class="technology-item-content">
                <h3>{{ $t('advantages.eco_design_eco_title') }}</h3>
                <p>{{ $t('advantages.eco_design_eco_dsc') }}</p>
              </div>
            </div>

            <div class="technology-item">
              <img src="@/assets/img/technology/technology4.svg" alt="item" class="technology-item-img"/>
              <div class="technology-item-content">
                <h3>{{ $t('advantages.eco_design_worldtech_title') }}</h3>
                <p>{{ $t('advantages.eco_design_worldtech_dsc') }}</p>
              </div>
            </div>

            <div class="technology-item">
              <img src="@/assets/img/technology/technology5.svg" alt="item" class="technology-item-img"/>
              <div class="technology-item-content">
                <h3>{{ $t('advantages.eco_design_paint_sprayer_title') }}</h3>
                <p>{{ $t('advantages.eco_design_paint_sprayer_dsc') }}</p>
              </div>
            </div>


          </div>
        </div>
      </div>
    </section>

    <transition name="fade" mode="out-in">
      <div class="gallery-block" v-if="show_gallery">
        <div class="gallery-block-content">
          <img :src="render_gallery_img" :alt="render_name" class="gallery-block-img"/>

          <a target="_blank" v-for="(product, index) in render_products" :key="index" :href="product.link" v-if="product.link !== ''" class="gallery-product-info" :style="{ top: product.css_top, left: product.css_left, right: product.css_right }" v-html="product.name"></a>

          <button @click="show_gallery = false" class="gallery-close-btn">
            <img src="@/assets/img/icons/close.svg" alt="close"/>
          </button>
        </div>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
     <div class="gallery-shadow" v-if="show_gallery"></div>
    </transition>

    <transition name="fade">
      <div id="pagetop" class="fixed right-0 bottom-0" v-show="scY > 300" @click="toTop">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
             stroke="#4a5568"
             stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs">
          <path d="M18 15l-6-6-6 6"/>
        </svg>
      </div>
    </transition>
  </main>
</template>

<script>
import i18n from "../i18n";
import {getLink, getCurrency} from "../utils/mixins";
import { scrollMeTo } from '../utils/functions';
import inspiration_data from '../utils/inspiration';

export default {
  name: 'Home',
  data() {
    return {
      url: '',
      imgix_url: 'https://jannowak.imgix.net/',
      category_id: 21,
      renders: [],
      products: [],
      scrollMeTo: scrollMeTo,

      scTimer: 0,
      scY: 0,

      hover: false,
      active: '',

      currency: '',

      tab_index: 0,
      tabs: ['#all', '#office', '#dress'],

      nextLabel: '<img src="' + require('../assets/img/icons/next.svg') + '" alt="image" />',
      prevLabel: '<img src="' + require('../assets/img/icons/prev.svg') + '" alt="image" />',

      render_name: '',
      render_gallery_img: '',
      render_products: [],
      show_gallery: false
    }
  },
  mounted () {
    this.setLink();
    this.setCurrency();
    this.getProducts();
    this.renders = inspiration_data;
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    setCurrency() {
      this.currency = getCurrency(i18n.locale);
    },
    setLink() {
      this.shop_url = 'https://jannowak'
      this.link = getLink(i18n.locale);

      this.url = this.shop_url + this.link
    },
    openAllTab() {
      this.$refs.all.activate();
      scrollMeTo('inspiration');
    },
    openSzatniaTab() {
      this.$refs.szatnia.activate();
      scrollMeTo('inspiration');
    },
    openOfficeTab() {
      this.$refs.office.activate();
      scrollMeTo('inspiration');
    },
    showGallery(index, name, gallery_img, products) {
      this.render_name = name;
      this.render_gallery_img = gallery_img;
      this.render_products = products;

      if (index !== 8) {
        this.show_gallery = true;
      }
    },
    roundPrice(price) {
      return parseFloat(price).toFixed(2);
    },
    getProducts() {
      this.$http.get(this.url + '/index.php?route=product/category/getProductsByCategoryId', {
        params: {
          'category_id': this.category_id
        },
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        console.log(res.data.products);
        this.products = res.data.products;
      })
    },
  }
}
</script>