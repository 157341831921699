<template>
  <div class="notfound">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1>Error 404</h1>
          <p>Nothing found, please click the button below to go back to the home page.</p>

          <a href="/" class="main-button">Back</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NotFound',
  }
</script>