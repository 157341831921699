<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="footer-content">
            <img src="@/assets/img/icons/logo-jannowak-white.svg" alt="logo" class="footer-logo"/>
            <a :href="shop_url + link + privacy" target="_blank">{{ $t('footer.privacy') }}</a>
            <p>&copy; {{ year }} {{ $t('footer.copyright') }}</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import i18n from "../i18n";
import { getLink, getPrivacy } from "../utils/mixins";

export default {
  name: 'Footer',
  data() {
    return {
      link: '',
      shop_url: '',
      privacy: '',
      year: new Date().getFullYear(),
    }
  },
  mounted() {
    this.setLink();
  },
  methods: {
    setLink() {
      this.shop_url = 'https://jannowak'

      this.link = getLink(i18n.locale);
      this.privacy = getPrivacy(i18n.locale);
    }
  }
}
</script>