import i18n from "../i18n";
import render_locales from "./render_locales";

const categories = [
    i18n.t('getinspired.eco_design_getinspired_home_office'),
    i18n.t('getinspired.eco_design_getinspired_cloakroom')
];

const inspiration_data = [
  {
    "thumbnail_img": require('../assets/img/thumbnail/1.png'),
    "gallery_img": require('../assets/img/gallery/1.jpg'),
    "category": categories[0],
    "name": "Kuba & Sara",
    "products": [
      {
        "name": "Model KUBA",
        "link": render_locales[i18n.locale].kuba_antracyt,
        "css_top": "20%",
        "css_left": "40%",
        "css_right": "auto"
      },
      {
        "name": "Model SARA",
        "link": render_locales[i18n.locale].sara_antracyt,
        "css_top": "35%",
        "css_left": "auto",
        "css_right": "20%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/2.png'),
    "gallery_img": require('../assets/img/gallery/2.jpg'),
    "category": categories[1],
    "name": "Kuba",
    "products": [
      {
        "name": "Model KUBA",
        "link": render_locales[i18n.locale].kuba_biala,
        "css_top": "30%",
        "css_left": "auto",
        "css_right": "5%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/3.png'),
    "gallery_img": require('../assets/img/gallery/3.jpg'),
    "category": categories[1],
    "name": "Bartek",
    "products": [
      {
        "name": "Model BARTEK",
        "link": render_locales[i18n.locale].bartek_biala,
        "css_top": "20%",
        "css_left": "auto",
        "css_right": "12%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/4.png'),
    "gallery_img": require('../assets/img/gallery/4.jpg'),
    "category": categories[0],
    "name": "Alex & Beata",
    "products": [
      {
        "name": "Model ALEX",
        "link": render_locales[i18n.locale].alex_biala,
        "css_top": "20%",
        "css_left": "25%",
        "css_right": "auto"
      },
      {
        "name": "Model Beata",
        "link": render_locales[i18n.locale].beata_biala,
        "css_top": "60%",
        "css_left": "auto",
        "css_right": "15%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/5.png'),
    "gallery_img": require('../assets/img/gallery/5.jpg'),
    "category": categories[0],
    "name": "Jan",
    "products": [
      {
        "name": "Model JAN",
        "link": render_locales[i18n.locale].jan_biala,
        "css_top": "30%",
        "css_left": "35%",
        "css_right": "auto"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/6.png'),
    "gallery_img": require('../assets/img/gallery/6.jpg'),
    "category": categories[0],
    "name": "Jan",
    "products": [
      {
        "name": "Model JAN",
        "link": render_locales[i18n.locale].jan_antracyt,
        "css_top": "35%",
        "css_left": "auto",
        "css_right": "25%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/7.png'),
    "gallery_img": require('../assets/img/gallery/7.jpg'),
    "category": categories[1],
    "name": "Kacper",
    "products": [
      {
        "name": "Model KACPER",
        "link": render_locales[i18n.locale].kacper_biala,
        "css_top": "30%",
        "css_left": "auto",
        "css_right": "40%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/8.png'),
    "gallery_img": require('../assets/img/gallery/8.jpg'),
    "category": categories[0],
    "name": "Sara & Kuba & Beata",
    "products": [
      {
        "name": "Model SARA",
        "link": render_locales[i18n.locale].sara_antracyt,
        "css_top": "50%",
        "css_left": "15%",
        "css_right": "auto"
      },
      {
        "name": "Model KUBA",
        "link": render_locales[i18n.locale].kuba_antracyt,
        "css_top": "8%",
        "css_left": "31%",
        "css_right": "auto"
      },
      {
        "name": "Model BEATA",
        "link": render_locales[i18n.locale].beata_antracyt,
        "css_top": "45%",
        "css_left": "auto",
        "css_right": "28%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/9.png'),
    "gallery_img": require('../assets/img/gallery/9.jpg'),
    "category": categories[0],
    "name": "Sara & Kuba & Beata",
    "products": [
      {
        "name": "Model SARA",
        "link": render_locales[i18n.locale].sara_antracyt,
        "css_top": "55%",
        "css_left": "25%",
        "css_right": "auto"
      },
      {
        "name": "Model KUBA",
        "link": render_locales[i18n.locale].kuba_antracyt,
        "css_top": "8%",
        "css_left": "auto",
        "css_right": "15%"
      },
      {
        "name": "Model BEATA",
        "link": render_locales[i18n.locale].beata_antracyt,
        "css_top": "50%",
        "css_left": "auto",
        "css_right": "2%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/10.png'),
    "gallery_img": require('../assets/img/gallery/10.jpg'),
    "category": categories[1],
    "name": "Kacper",
    "products": [
      {
        "name": "Model KACPER",
        "link": render_locales[i18n.locale].kacper_antracyt,
        "css_top": "40%",
        "css_left": "auto",
        "css_right": "20%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/11.png'),
    "gallery_img": require('../assets/img/gallery/11.jpg'),
    "category": categories[1],
    "name": "Igor",
    "products": [
      {
        "name": "Model IGOR",
        "link": render_locales[i18n.locale].igor_biala,
        "css_top": "30%",
        "css_left": "auto",
        "css_right": "40%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/12.png'),
    "gallery_img": require('../assets/img/gallery/12.jpg'),
    "category": categories[1],
    "name": "Bartek",
    "products": [
      {
        "name": "Model BARTEK",
        "link": render_locales[i18n.locale].bartek_biala,
        "css_top": "30%",
        "css_left": "auto",
        "css_right": "25%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/13.png'),
    "gallery_img": require('../assets/img/gallery/13.jpg'),
    "category": categories[0],
    "name": "Kuba & Sara",
    "products": [
      {
        "name": "Model KUBA",
        "link": render_locales[i18n.locale].kuba_antracyt,
        "css_top": "20%",
        "css_left": "30%",
        "css_right": "auto"
      },
      {
        "name": "Model SARA",
        "link": render_locales[i18n.locale].sara_antracyt,
        "css_top": "25%",
        "css_left": "auto",
        "css_right": "3%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/14.png'),
    "gallery_img": require('../assets/img/gallery/14.jpg'),
    "category": categories[1],
    "name": "Bartek",
    "products": [
      {
        "name": "Model BARTEK",
        "link": render_locales[i18n.locale].bartek_antracyt,
        "css_top": "30%",
        "css_left": "auto",
        "css_right": "25%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/15.png'),
    "gallery_img": require('../assets/img/gallery/15.jpg'),
    "category": categories[0],
    "name": "Alex",
    "products": [
      {
        "name": "Model ALEX",
        "link": render_locales[i18n.locale].alex_biala,
        "css_top": "25%",
        "css_left": "auto",
        "css_right": "35%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/16.png'),
    "gallery_img": require('../assets/img/gallery/16.jpg'),
    "category": categories[1],
    "name": "Igor",
    "products": [
      {
        "name": "Model IGOR",
        "link": render_locales[i18n.locale].igor_antracyt,
        "css_top": "25%",
        "css_left": "auto",
        "css_right": "35%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/17.png'),
    "gallery_img": require('../assets/img/gallery/17.jpg'),
    "category": categories[0],
    "name": "Alex & Kevin",
    "products": [
      {
        "name": "Model ALEX",
        "link": render_locales[i18n.locale].alex_biala,
        "css_top": "10%",
        "css_left": "16%",
        "css_right": "auto"
      },
      {
        "name": "Model KEVIN",
        "link": render_locales[i18n.locale].kevin_biala,
        "css_top": "30%",
        "css_left": "auto",
        "css_right": "30%"
      }
    ]
  },
  {
    "thumbnail_img": require('../assets/img/thumbnail/18.png'),
    "gallery_img": require('../assets/img/gallery/18.jpg'),
    "category": categories[1],
    "name": "Igor",
    "products": [
      {
        "name": "Model IGOR",
        "link": render_locales[i18n.locale].igor_biala,
        "css_top": "20%",
        "css_left": "auto",
        "css_right": "33%"
      }
    ]
  }
];

export default inspiration_data;