<template>
  <header>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="header-content">
            <a @click="scrollMeTo('main')">
              <img src="@/assets/img/icons/logo-design-white.svg" alt="logo" class="logo-img"/>
            </a>

            <div class="menu">
              <a :href="shop_url + link" class="link">{{ $t('header.eco_design_home') }}</a>
              <a @click="scrollMeTo('inspiration')" class="link">{{ $t('header.eco_design_inspiration') }}</a>
              <a @click="scrollMeTo('wardrobes')" class="link">{{ $t('header.eco_design_products') }}</a>
              <a @click="scrollMeTo('technology')" class="link">{{ $t('header.eco_design_technology') }}</a>

              <a :href="shop_url + link + 'eco-design'" class="main-button">{{ $t('header.eco_design_buy_now') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { scrollMeTo } from '../utils/functions';
import { getLink } from "../utils/mixins";
import i18n from "../i18n";

export default {
  name: 'Header',
  data() {
    return {
      link: '',
      shop_url: '',
      scrollMeTo: scrollMeTo
    }
  },
  mounted() {
    this.setLink();
  },
  methods: {
    setLink() {
      this.shop_url = 'https://jannowak';
      this.link = getLink(i18n.locale);
    }
  }
}
</script>