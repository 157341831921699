const render_locales = {
    "pl": {
        "kuba_antracyt": 'https://jannowak.com/229-szafa-biurowa-kuba-eco-design-antracytowa',
        "kuba_biala": 'https://jannowak.com/228-szafa-biurowa-kuba-eco-design-biala',
        "sara_antracyt": 'https://jannowak.com/220-szafa-biurowa-sara-v4-eco-design-antracytowa',
        "bartek_antracyt": 'https://jannowak.com/208-szafa-metalowa-bartek-eco-design-antracytowa',
        "bartek_biala": 'https://jannowak.com/207-szafa-metalowa-bartek-eco-design-biala',
        "alex_biala": 'https://jannowak.com/216-szafa-biurowa-alex-eco-design-biala',
        "beata_antracyt": 'https://jannowak.com/219-szafa-biurowa-beata-eco-design-antracytowa',
        "beata_biala": 'https://jannowak.com/218-szafa-biurowa-beata-eco-design-biala',
        "jan_antracyt": 'https://jannowak.com/203-szafa-biurowa-jan-eco-design-antracytowa',
        "jan_biala": 'https://jannowak.com/204-szafa-biurowa-jan-eco-design-biala',
        "kacper_biala": 'https://jannowak.com/226-szafa-biurowa-kacper-eco-design-biala',
        "kacper_antracyt": 'https://jannowak.com/227-szafa-biurowa-kacper-eco-design-antracytowa',
        "igor_biala": 'https://jannowak.com/205-szafa-metalowa-igor-eco-design-biala',
        "igor_antracyt": 'https://jannowak.com/206-szafa-metalowa-igor-eco-design-antracytowa',
        "kevin_biala": 'https://jannowak.com/224-szafa-biurowa-kevin-eco-design-biala'
    },
    "cs": {
        "kuba_antracyt": 'https://jannowak.cz/229-kovova-skrin-s-posuvnymi-dvermi-a-policemi-model-kuba-antracitova-orech',
        "kuba_biala": 'https://jannowak.cz/228-kovova-skrin-s-posuvnymi-dvermi-a-policemi-model-kuba-bila-dub-sonoma',
        "sara_antracyt": 'https://jannowak.cz/220-kovova-kartoteka-model-sara-antracitova-orech',
        "bartek_antracyt": 'https://jannowak.cz/208-kovova-satni-skrin-na-nozkac-s-10-boxy-bartek-antracitova-orech',
        "bartek_biala": 'https://jannowak.cz/207-kovova-satni-skrin-na-nozkac-s-10-boxy-bartek-bila-dub-sonoma',
        "alex_biala": 'https://jannowak.cz/216-kovova-skrin-model-alex-bila-dub-sonoma-eco-design',
        "beata_antracyt": 'https://jannowak.cz/219-kovova-skrinka-s-policemi-model-beata-antracitova-orech',
        "beata_biala": 'https://jannowak.cz/218-kovova-skrinka-s-policemi-model-beata-bila-dub-sonoma-eco-design',
        "jan_antracyt": 'https://jannowak.cz/203-kovova-skrin-jan-eco-design-antracitova-orech',
        "jan_biala": 'https://jannowak.cz/204-kovova-policova-skrin-jan-eco-design-bila-dub-sonoma',
        "kacper_biala": 'https://jannowak.cz/226-plechova-satni-skrin-na-soklu-4-moduly-s-polici-model-kacper-2-bila-dub-sonoma',
        "kacper_antracyt": 'https://jannowak.cz/227-kovova-skrin-s-posuvnymi-dvermi-a-policemi-model-kuba-antracitova-orech',
        "igor_biala": 'https://jannowak.cz/205-kovova-satni-skrin-igor-bila-dub-sonoma',
        "igor_antracyt": 'https://jannowak.cz/206-kovova-satni-skrin-igor-antracitova-orech',
        "kevin_biala": 'https://jannowak.cz/224-kovova-policova-skrin-model-kevin-bila-dub-sonoma'
    },
    "sk": {
        "kuba_antracyt": 'https://jannowak.sk/229-kovova-spisova-policova-skrina-s-posuvnymi-dverami-model-kuba-antracitova-orech',
        "kuba_biala": 'https://jannowak.sk/228-kovova-spisova-policova-skrina-s-posuvnymi-dverami-model-kuba-biela-dub-sonoma',
        "sara_antracyt": 'https://jannowak.sk/220-kovova-kartoteka-model-sara-antracitova-orech',
        "bartek_antracyt": 'https://jannowak.sk/208-plechova-satnova-skrina-na-nozkach-s-10-boxami-bartek-antracitova-orech',
        "bartek_biala": 'https://jannowak.sk/98-plechova-satnova-skrina-na-nozkach-s-10-boxami-bartek-biela-dub-sonoma',
        "alex_biala": 'https://jannowak.sk/216-kovova-spisova-skrina-model-alex-biela-eco-design',
        "beata_antracyt": 'https://jannowak.sk/219-kovova-spisova-skrinka-model-beata-antracitova-orech',
        "beata_biala": 'https://jannowak.sk/218-kovova-spisova-skrinka-model-beata-biela-dub-sonoma-eco-design',
        "jan_antracyt": 'https://jannowak.sk/203-kovova-skrina-jan-h-antracitova',
        "jan_biala": 'https://jannowak.sk/204-kovova-spisova-skrina-jan-eco-design-biela-dub-sonoma',
        "kacper_biala": 'https://jannowak.sk/226-plechova-satnova-stvormodulova-skrina-na-sokli-s-policou-model-kacper-2-biela-dub-sonoma',
        "kacper_antracyt": 'https://jannowak.sk/227-kovova-spisova-policova-skrina-s-posuvnymi-dverami-model-kuba-antracitova-orech',
        "igor_biala": 'https://jannowak.sk/205-kovova-satnova-skrina-igor-biela-dub-sonoma',
        "igor_antracyt": 'https://jannowak.sk/206-kovova-satnova-skrina-igor-antracitova-orech',
        "kevin_biala": 'https://jannowak.sk/224-kovova-spisova-skrina-model-kevin-biela-dub-sonoma'
    },
    "hu": {
        "kuba_antracyt": 'https://jannowak.hu/229-fem-iratszekreny-polcokkal-toloajtokkal-kuba-modell-antracyt-dio',
        "kuba_biala": 'https://jannowak.hu/228-fem-iratszekreny-polcokkal-toloajtokkal-kuba-modell-feher-sonoma-tolgy',
        "sara_antracyt": 'https://jannowak.hu/220-sara-fem-irattarto-szekreny-antracyt-dio',
        "bartek_antracyt": 'https://jannowak.hu/208-10-rekeszes-oltozoszekreny-labakon-bartek-antracit-dio',
        "bartek_biala": 'https://jannowak.hu/98-10-rekeszes-oltozoszekreny-labakon-bartek-feher-sonoma-tolgy',
        "alex_biala": 'https://jannowak.hu/216-fem-iratszekreny-alex-modell-feher-sonoma-tolgy-eco-design',
        "beata_antracyt": 'https://jannowak.hu/219-fem-iratszekreny-beata-modell-antracyt-dio',
        "beata_biala": 'https://jannowak.hu/218-fem-iratszekreny-beata-modell-feher-sonoma-tolgy-eco-design',
        "jan_antracyt": 'https://jannowak.hu/203-iratszekreny-jan-eco-design-antracit-dio',
        "jan_biala": 'https://jannowak.hu/204-iratszekreny-jan-eco-design-feher-sonoma-tolgy',
        "kacper_biala": '',
        "kacper_antracyt": 'https://jannowak.hu/227-fem-iratszekreny-polcokkal-toloajtokkal-kuba-modell-antracit-dio',
        "igor_biala": 'https://jannowak.hu/205-oltozoszekreny-igor-feher-sonoma-tolgy',
        "igor_antracyt": 'https://jannowak.hu/206-oltozoszekreny-igor--antracit-dio',
        "kevin_biala": ''
    }
};

export default render_locales;